/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(".close-contact-box").click(function(){
            $(document.body).removeClass('noscroll');
            $(".contact-box").fadeOut(1000);
        });
        $(".menu-contact").click(function(){
            $(document.body).addClass('noscroll');
            $(".contact-box").fadeIn(1000);
        });
        $('.contact-box').css('overflow', 'hidden');

        //Filterbox
        $(".close-contact-box").click(function(){
            $(".filter-box").fadeOut(1000);
        });
        $(".filter").click(function(){
            $(".filter-box").fadeIn(1000);
        });


        //Remove fixed position from left window at bottom
        $(window).scroll(function() {
           $(".fixed-left").removeClass("relative-left");
           if($(window).scrollTop() + $(window).height() > ($(document).height() - 100) ) {
               //you are at bottom
               $(".fixed-left").addClass("relative-left");
           }
        });


        $(function(){
          $('#menu').slicknav();
        });

        //Initiate mixitup filter 
        var mixer = mixitup('.mixup');

        //Initiate wow animations
        new WOW().init();



        //Team section
        function getInfo(src){
          var info = {};
          info.img = $(src).find('img').attr('src');
          info.name = $(src).find('.team-name').html();
          info.pos = $(src).find('.team-pos').html();
          info.bio = $(src).find('.team-bio').html();
          return info;
        }

        function setInfo(info){
          var box = $('.team-box');
          box.find('.contact-left img').attr('src', info.img);
          //box.find('.contact-left').css('background-image', 'url("' + info.img + '")');
          box.find('.team-name').html(info.name); 
          box.find('.team-pos').html(info.pos);
          box.find('.team-bio').html(info.bio);
        }

        function nextMember(current){
          var box = $('.team-box');
          var next = $(current).nextAll('div:visible').first();
          if (next.length) {
            box.find('.team-box-next').show();
            box.find('.team-box-next').data('id', next.data('order'))
            var next_info = getInfo(next);
            box.find('.team-box-next-img img').attr('src', next_info.img);
            box.find('.team-box-next-name').html(next_info.name); 
            box.find('.team-box-next .team-pos').html(next_info.pos);
          } else {
            box.find('.team-box-next').hide();
          }
        }


        $('.team-section .row').on('click', function(e){
          e.preventDefault();

          $('.team-section .row').removeClass('__active');
          $(this).addClass('__active');
          
          setInfo(getInfo(this)); 
          nextMember(this);


          $('.team-box').fadeIn(400);
          $(document.body).addClass('noscroll');
        })


        $('.team-contact-box').on('click', function(e){
          e.preventDefault();
          $('.team-box').fadeOut(400);
          $('.team-section .row').removeClass('__active');
          $(document.body).removeClass('noscroll');
        })

        $('.team-box-next').on('click', function(e){
          e.preventDefault();
          var id = $(this).data('id');
          $('.team-section .row[data-order="' + id + '"]').click();
        })


        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page        
            
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
